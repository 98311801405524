<!--
 * @Description: 
 * @Author: huacong
 * @Date: 2021-04-02 11:33:25
 * @LastEditTime: 2021-07-19 17:47:11
 * @LastEditors: huacong
-->
<template>
  <!-- 企业-总页面 -->
  <div>
    <comNose></comNose>
    <router-view class="comBg"></router-view>
    <pithyTail></pithyTail>
  </div>
</template>
<script>
import comNose from "@/components/comNose/comNose";
import pithyTail from "@/components/pithyTail/pithyTail";
export default {
  components: {
    comNose,
    pithyTail,
  },
};
</script>
