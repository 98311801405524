<template>
  <!-- 人才个人中心--头部 -->
  <div>
    <div class="userInfoNav">
      <span class="lander">
        <span>{{ userInfo.u_phone }}</span>
        <span> | </span>
        <span @click="goLogin">退出登录</span>
      </span>
    </div>
    <div class="nosebox">
      <section style="position:relative">
        <a class="logo" @click="goHome">
          <img :src="logo" alt="logo" />
        </a>
        <ul class="menu-list">
          <li>
            <a @click="goPath('/comPage/index')">
              首页
            </a>
          </li>
          <li class="li-ulList">
            <a>
              账户管理
            </a>
            <ul class="li-menu">
              <li>
                <a @click="goPath('/comPage/comHome')">
                  猎头主页
                </a>
              </li>
              <li>
                <a @click="goPath('/comPage/personal')">
                  个人资料
                </a>
              </li>
              <li>
                <a @click="goPath('/comPage/accountSafety')">
                  账号安全
                </a>
              </li>
              <li>
                <a @click="goPath('/comPage/realCenter')">
                  认证中心
                </a>
              </li>
              <li>
                <a @click="goPath('/comPage/aftercomrealSel')">
                  企业认证
                </a>
              </li>
            </ul>
          </li>
          <li>
            <!-- <router-link :to="{ path: '/comPage/comFirstLogin' }">
              公司资料
            </router-link> -->
            <a @click="goPath('/comPage/aftercompleteInfo')">
              公司资料
            </a>
          </li>
          <li class="li-ulList">
            <a @click="goPath('/comPage/recruitInfo')">
              信息管理
            </a>
          </li>
          <li class="li-ulList">
            <a>
              简历管理
            </a>
            <ul class="li-menu">
              <li>
                <a @click="goPath('/comPage/receipt')">
                  收到的简历
                </a>
              </li>
              <li>
                <a @click="goPath('/comPage/downloadResume')">
                  下载的简历
                </a>
              </li>
              <li>
                <a @click="goPath('/comPage/smartResume')">
                  简历搜索
                </a>
              </li>
            </ul>
          </li>
          <li class="li-ulList">
            <a>
              会员服务
            </a>
            <ul class="li-menu">
              <li>
                <a @click="goPath('/comPage/vipIndex')">
                  我的账户
                </a>
              </li>
              <li>
                <a @click="goPath('/comPage/vipOrder')">
                  我的订单
                </a>
              </li>
              <li>
                <a @click="goPath('/comPage/vipPay')">
                  购买会员
                </a>
              </li>
            </ul>
          </li>
          <li class="li-ulList">
            <a>
              消息管理
            </a>
            <ul class="li-menu" style="width: 85px;">
              <li>
                <a @click="goPath('/comPage/comMessage')">
                  系统消息
                </a>
              </li>
              <li>
                <a @click="goPath('/comPage/comfeeback')">
                  我的反馈
                </a>
              </li>
            </ul>
          </li>
          <!-- <li class="li-ulList">
            <a>
              资金管理
            </a>
          </li> -->
        </ul>
        <!-- <div class="comMark" v-if="markShow" @click="markClick"></div> -->
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import $ from "jquery";
export default {
  name: "nose",
  data() {
    return {
      logo: require("@/assets/img/logo.png"),
      markShow: false,
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    userInfo() {
      let k = this.$store.state.userInfo;
      if (JSON.stringify(k) == "{}") {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  watch: {},
  methods: {
    goLogin() {
      localStorage.removeItem("Authorization");
      this.$router.push({ path: "/Login" });
    },
    goHome() {
      this.$router.push({ path: "/Home/index" });
    },
    hoverMenu() {
      $(".li-ulList").hover(
        function() {
          $(this)
            .children(".li-menu")
            .show();
        },
        function() {
          $(this)
            .children(".li-menu")
            .hide();
        }
      );
    },
    goPath(src) {
      let routerUrl = this.$route.path;
      if (
        routerUrl == "/comPage/comFirstLogin" ||
        routerUrl == "/comPage/comrealSel" ||
        routerUrl == "/comPage/haveComReal"
      ) {
        this.$message.warning("您需要先完成关联认证企业！");
        return;
      }
      this.$router.push({ path: src });
      $(".li-menu").hide();
    },
    getUrl() {
      let routerUrl = this.$route.path;
      console.log(routerUrl);
      if (
        routerUrl == "/comPage/comFirstLogin" ||
        routerUrl == "/comPage/comrealSel" ||
        routerUrl == "/comPage/haveComReal"
      ) {
        this.markShow = true;
      } else {
        this.markShow = false;
      }
    },
    markClick() {
      this.debounce();
    },
    debounce() {
      let canRun = true; // 通过闭包保存一个标记
      // return function() {
      // 在函数开头判断标记是否为true，不为true则return
      if (!canRun) return;
      // 立即设置为false
      canRun = false;
      // 将外部传入的函数的执行放在setTimeout中
      setTimeout(() => {
        this.handle();
        canRun = true;
      }, 3000);
      // };
    },
    // 处理函数
    handle() {
      this.$message.warning("您需要先完成关联认证企业！");
    },
  },
  mounted() {
    this.hoverMenu();
    this.getUrl();
  },
};
</script>
<style scoped>
.userInfoNav {
  text-align: right;
  width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}
.nosebox {
  min-width: 1240px;
  background-color: #131d28;
}
section .logo {
  margin-top: 5px;
}
section {
  height: 80px;
  display: flex;
  align-items: center;
  background-color: #131d28;
}
.menu-list {
  display: inline-block;
  list-style: none;
  margin-left: auto;
}
.menu-list > li {
  display: block;
  float: left;
  color: #fff;
  cursor: pointer;
  padding: 10px 0;
}
.menu-list li a {
  color: #fff;
  padding: 10px 23px;
}
.menu-list li:last-child a {
  padding-right: 0;
}
.menu-list > li:hover > a {
  color: #b1b6ca;
}
.menu-list li:last-child {
  padding-right: 0px;
}
.li-ulList {
  position: relative;
}
.li-menu {
  display: none;
  position: absolute;
  top: 38px;
  padding: 6px 0;
  background: #131d28;
  list-style: none;
  z-index: 10;
}
.li-menu li a {
  display: inline-block;
  padding: 10px 16px;
}
.li-menu li a:hover {
  color: #b1b6ca;
}
.loginBtn span,
.lander span {
  cursor: pointer;
}
.lander {
  margin-left: auto;
  color: #333;
}
.comMark {
  width: 800px;
  height: 40px;
  position: absolute;
  top: 20px;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
}
</style>
